import {
  aboutPath,
  adminDashboardPath,
  announcementsPath,
  brandAmbassadorDashboardPath,
  careersPath,
  checkoutPath,
  classRepDashboardPath,
  classRepWizardPath,
  contentCreatorDashboardPath,
  contestPath,
  coursesPath,
  educatorsLandingPath,
  essayWriterLandingPagePath,
  essayWriterPath,
  highSchoolLandingPath,
  instructorDashboardPath,
  invitesPagePath,
  jobApplicantPath,
  livePrepLandingPath,
  lsatLandingPath,
  marketingRepDashboardPath,
  mcatEliteLandingPath,
  mcatExamDates,
  mcatFreeResources,
  mcatLandingEventsPath,
  mcatRepDashboardPath,
  medSchoolCalculatorPath,
  onDemandLandingPath,
  parentsLandingPath,
  partnershipsPath,
  preMedPlusLandingPath,
  pricingPath,
  scholarshipsPath,
  searchPagePath,
  studentDashboardPath,
  studentOnboardingPath,
  studentSignupPath,
  subscriptionLandingPath,
  teacherDashboardPath,
  tokensDashboardPath,
  tutoringDashboardPath,
  tutoringLandingPath,
  universityLandingPath,
} from 'my-core/routes';

export const NO_APPBAR_ROUTES = [
  /\/online-courses\/\d+\/print/,
  /\/study-rooms/,
  studentSignupPath(),
  studentOnboardingPath(),
  checkoutPath(),
  classRepWizardPath(''),
];

export const ALT_PATHS = [
  { path: '/', exact: true, type: 'dark' },
  { path: '/homepage-alt', type: 'dark' },
  { path: '/homepage', type: 'dark' },
  { path: aboutPath(), type: 'light' },
  { path: careersPath(), type: 'color' },
  { path: contestPath(), type: 'light' },
  { path: /\/courses\/[^/]+\/booklet/, type: 'light' }, // course booklet page
  { path: /\/courses\/[^/]+/, type: 'dark' },
  { path: coursesPath().pathname, type: 'light' },
  { path: educatorsLandingPath(), type: 'light' },
  { path: highSchoolLandingPath(), type: 'light' },
  { path: invitesPagePath() },
  { path: '/job-positions', type: 'color' },
  { path: livePrepLandingPath(), type: 'color' },
  { path: mcatEliteLandingPath(), exact: true, type: 'color' },
  { path: mcatLandingEventsPath(), type: 'dark' },
  { path: mcatExamDates(), type: 'light' },
  { path: mcatFreeResources(), type: 'dark' },
  { path: medSchoolCalculatorPath(), type: 'color' },
  { path: lsatLandingPath(), type: 'light' },
  { path: preMedPlusLandingPath(), type: 'dark' },
  { path: onDemandLandingPath(), type: 'color' },
  { path: /\/online-courses\/\d+$/, type: 'light' },
  // { path: /\/online-courses\/\d+\/manager/, type: 'color' },
  { path: parentsLandingPath(), type: 'light' },
  { path: /\/partners\// },
  { path: partnershipsPath(), type: 'light' },
  { path: pricingPath(), type: 'light' },
  { path: scholarshipsPath(), exact: true },
  { path: '/schools/', type: 'color' },
  { path: searchPagePath(), type: 'light' },
  { path: studentDashboardPath(), exact: true, type: 'light' },
  { path: subscriptionLandingPath() },
  { path: teacherDashboardPath(), type: 'color' },
  { path: tokensDashboardPath(), type: 'light' },
  { path: tutoringDashboardPath(), type: 'color' },
  { path: tutoringLandingPath(), type: 'medium' },
  { path: '/tutors' },
  { path: universityLandingPath(), type: 'light' },
  { path: '/online-course-activities', type: 'color' },
  { path: /\/textbooks\/[^/]+(\/[^/]+)?$/, type: 'color' },
  { path: /\/textbooks\/.*\/sections\//, type: 'light' },
  { path: essayWriterPath(), exact: true, type: 'color' },
  { path: essayWriterLandingPagePath(), exact: true, type: 'light' },
  { path: '/admin', type: 'light' },
];

export const ROLE_ROUTES = [
  ['marketing_rep', { label: 'Marketing Dashboard', path: marketingRepDashboardPath() }],
  ['class_rep', { label: 'Class Rep Dashboard', path: classRepDashboardPath() }],
  ['mcat_rep', { label: 'MCAT Rep Dashboard', path: mcatRepDashboardPath() }],
  [['class_rep', 'marketing_rep'], { label: 'Announcements', path: announcementsPath() }],
  ['job_applicant', { label: 'Job Application', path: jobApplicantPath() }],
  ['brand_ambassador', { label: 'Brand Ambassador Dashboard', path: brandAmbassadorDashboardPath() }],
];

export const VARIANTS = {
  admin: {
    left: [{ path: adminDashboardPath(), label: 'Home' }],
    collapsed: [
      { path: instructorDashboardPath(), label: 'Educator Home' },
      { path: studentDashboardPath(), label: 'Student Home' },
      { path: tutoringDashboardPath(), label: 'Tutoring Home' },
      { path: teacherDashboardPath(), label: 'Teacher Home' },
    ],
  },
  // are we still using this role?
  content_creator: {
    left: [{ path: contentCreatorDashboardPath(), label: 'Dashboard' }],
    collapsed: [{ path: studentDashboardPath(), label: 'Student Home' }],
  },
  guest: {},
  instructor: {
    left: [{ path: instructorDashboardPath(), label: 'Home' }],
    collapsed: [{ path: studentDashboardPath(), label: 'Student Home' }],
  },
  student: {
    left: [{ path: studentDashboardPath(), label: 'Dashboard' }],
  },
  tutor: {
    left: [{ path: instructorDashboardPath(), label: 'Home' }],
    collapsed: [{ path: studentDashboardPath(), label: 'Student Home' }],
  },
  teacher: {
    left: [{ path: teacherDashboardPath(), label: 'Home' }],
  },
  manager: {
    left: [{ path: adminDashboardPath(), label: 'Home' }],
    collapsed: [{ path: studentDashboardPath(), label: 'Student Home' }],
  },
};
