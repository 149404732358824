/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import { DeviceUUID } from 'device-uuid';
/*******************
 *    MIXPANEL     *
 *******************/
import mixpanelLib from 'mixpanel-browser/src/loaders/loader-module-with-async-recorder';
// https://github.com/mixpanel/mixpanel-js/releases/tag/v2.54.0
import queryString from 'query-string';
import { UAParser } from 'ua-parser-js';

import { CURRENT_TIMEZONE_NAME } from 'my-core/date-utils';
import { googleLogout } from 'my-core/google-sign-in';
import { chatSupportOpened } from 'my-core/gtm-events';
import { insertScript } from 'my-core/script-utils';
import { isMobileDevice } from 'my-core/user-agent-utils';
import { installWizeTracking } from 'my-core/wize-event-tracking';

export const DEVICE_INFO = UAParser();
export const DEVICE_UUID = new DeviceUUID().get();

export { wizeTrack, wizeTrack2 } from 'my-core/wize-event-tracking';

// disable tracking when Prerender service useragent
export const TRACKING_DISABLED = /prerender|googlebot|ahrefs|chrome-lighthouse/i.test(window?.navigator?.userAgent);

export function install(user, subscription) {
  intercomInstall(user);
  mixpanelInstall(user, subscription);
  gaInstall(user);
  facebookInstall(user);
  installWizeTracking(user);
  installShareASale(user);
  installCustomerIO(user);
  installHotjar(user, subscription);
  installTikTok(user);
  installHubspot(user);

  trackPageView(1000);
  if (!user.guest) Sentry.setUser({ id: user.id });
}

export function pageChange(user, subscription, location) {
  intercomUpdate(user, location);
  trackPageView();
}

export function userUpdate(user, subscription) {
  mixpanelUpdate(user, subscription);
  shareASaleUpdate(user);
}

export function logout() {
  intercomUninstall();
  mixpanelUninstall();
  googleLogout();
}

// try to de-duplicate calls caused by instant redirects by debouncing calls and verify that url is different
let pageViewInitialLoad = true;
let pageViewTimeout = null;
let pageViewHref = null;
function trackPageView(timeout) {
  if (pageViewTimeout) clearTimeout(pageViewTimeout);
  pageViewTimeout = setTimeout(() => {
    const href = window.location.href;
    if (href === pageViewHref) return;
    pageViewHref = href;
    if (__DEV__) console.log('Track Page View', href, pageViewInitialLoad);
    if (process.env.MIXPANEL_TOKEN) mixpanelLib.track_pageview({ initial_load: pageViewInitialLoad });
    window._cio?.page(href);
    if (!pageViewInitialLoad) {
      trackHubspot('setPath', window.location.pathname + window.location.search);
      trackHubspot('trackPageView');
    }
    pageViewInitialLoad = false;
  }, timeout || 400);
}

/**********************
 * GOOGLE TAG MANAGER *
 *********************/
export const dataLayer = {
  push(...args) {
    if (window.gtmDataLayer) window.gtmDataLayer.push(...args);
    else {
      if (__DEV__) console.log('gtmDataLayer.push', args);
      args[0]?.eventCallback?.();
    }
  },
};

/**********************
 * GOOGLE ANALYTICS   *
 *********************/
const gtagSuperProps = {};

export function gtag(...args) {
  if (args[0] === 'event') args[2] = { ...gtagSuperProps, ...args[2] };
  if (__DEV__) console.log('gtag:', args);
  if (window.gtag) return window.gtag(...args);
  window.dataLayer ||= [];
  const gtag = (window.gtag = function () {
    window.dataLayer.push(arguments);
  });
  gtag(...args);
}

function gaInstall(user) {
  if (process.env.GOOGLE_ANALYTICS_ID) {
    insertScript(`https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_ID}`);
  }
  gtag('js', new Date());
  gtagSuperProps.user_type = user.user_type;
  gtagSuperProps.user_school_name = user.school_name;

  if (!user.guest) {
    gtag('set', { user_id: user.id });
    gtag('set', 'user_properties', {
      user_type: user.user_type,
      user_school_id: user.school_id,
      user_school_name: user.school_name,
    });
  }
  gtag('config', process.env.GOOGLE_ANALYTICS_ID);
}

/*******************
 *    FACEBOOK     *
 *******************/
function installFbProxy() {
  if (window.fbq) return;
  const n = (window.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  });
  if (!window._fbq) window._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = '2.0';
  n.queue = [];
}

export function fbevents(...args) {
  if (__DEV__) console.log('fbevents:', args);
  installFbProxy();
  window.fbq?.(...args);
}

function facebookInstall(user) {
  if (process.env.FB_PIXEL_ID) {
    insertScript('https://connect.facebook.net/en_US/fbevents.js');
  }
  fbevents('init', process.env.FB_PIXEL_ID, user.guest ? {} : { em: user.email });
  fbevents('track', 'PageView');
}

/*******************
 *    INTERCOM     *
 *******************/
function installIntercomProxy() {
  if (window.Intercom) return;
  const Intercom = (window.Intercom = function () {
    Intercom.c(arguments);
  });
  Intercom.q = [];
  Intercom.c = function (args) {
    Intercom.q.push(args);
  };
}

export function Intercom(...args) {
  if (__DEV__) console.log('Intercom: ', ...args);
  installIntercomProxy();
  window.Intercom(...args);
}

function intercomInstall(user) {
  if (process.env.INTERCOM_APP_ID) {
    insertScript(`https://widget.intercom.io/widget/${process.env.INTERCOM_APP_ID}`);
  }
  Intercom('boot', getBootIntercomSettings(user));
  Intercom('onShow', chatSupportOpened);
}
function intercomUpdate(user) {
  Intercom('update', getUpdateIntercomSettings(user));
}

function intercomUninstall() {
  Intercom('shutdown');
}

function getUpdateIntercomSettings(user) {
  // send last_request_at to make sure intercom is pinged, since it's unlikely that other attributes
  // have changed: https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
  const last_request_at = parseInt(Date.now() / 1000);
  if (!user.guest) {
    return {
      user_id: user.id,
      email: user.email,
      user_hash: user.ic_user_hash,
      last_request_at,
    };
  } else {
    return { last_request_at };
  }
}

function getBootIntercomSettings(user) {
  const isMobile = isMobileDevice();
  const config = { app_id: process.env.INTERCOM_APP_ID };
  if (!user.guest) {
    Object.assign(config, {
      user_id: user.id,
      name: user.name,
      email: user.email,
      created_at: parseInt(new Date(user.created_at).getTime() / 1000),
      user_hash: user.ic_user_hash,
    });
    // this setting will override the visibility conditions set on intercom.io
    if (isMobile) {
      config.hide_default_launcher = true;
    }
  }
  return config;
}

export const mixpanel = {
  track(...args) {
    if (__DEV__) console.log('Mixpanel: ', ...args);
    if (mixpanelLib.config) {
      mixpanelLib.track(...args);
    } else {
      // 4th arg may be callback
      if (typeof args[3] === 'function') args[3]();
    }
  },
  identify(...args) {
    if (mixpanelLib.config) {
      mixpanelLib.identify(...args);
    }
  },
  register(...args) {
    if (mixpanelLib.config) {
      mixpanelLib.register(...args);
    }
  },
};
function mixpanelInstall(user, subscription) {
  if (process.env.MIXPANEL_TOKEN) {
    // `skip_first_touch_marketing`, `store_google` config to disable default mixpanel handling of utm params -- register & people.set(_once)
    mixpanelLib.init(process.env.MIXPANEL_TOKEN, { skip_first_touch_marketing: true, store_google: true });
    const campaignParams = getCampaignParams();
    if (campaignParams) {
      mixpanelLib.people.set(campaignParams.params);
      mixpanelLib.people.set_once(campaignParams.firstParams);
      mixpanelLib.register(campaignParams.params);
    }
    if (!user.guest) {
      mixpanelLib.identify(user.id);
      updateMixpanelUser(user, subscription);
    }
    mixpanelLib.register({
      user_agent: DEVICE_INFO.ua,
      device_uuid: DEVICE_UUID,
      release_version:
        process.env.HEROKU_RELEASE_VERSION &&
        (+process.env.HEROKU_RELEASE_VERSION.replace(/^v/, '') || process.env.HEROKU_RELEASE_VERSION),
    });
  }
}

function mixpanelUpdate(user, subscription) {
  if (process.env.MIXPANEL_TOKEN) {
    updateMixpanelUser(user, subscription);
  }
}

let prevMPUpdateProps = {};
function updateMixpanelUser(user, subscription) {
  const userProps = {
    $email: user.email,
    $created: user.created_at,
    $first_name: user.first_name,
    $last_name: user.last_name,
    $country_code: user.country_code,
    $avatar: user.avatar.is_silhouette ? undefined : user.avatar.normal,
    roles: user.roles,
    school_name: user.school_name,
    school_type: user.school_type,
    school_id: user.school_id,
    subscription_status: subscription?.status,
    paying_user: user.paying_user,
  };
  const regProps = {
    user_type: user.user_type,
    subscription_status: subscription?.status,
    paying_user: user.paying_user,
  };
  if (JSON.stringify(userProps) !== JSON.stringify(prevMPUpdateProps.userProps)) mixpanelLib.people.set(userProps);
  if (JSON.stringify(regProps) !== JSON.stringify(prevMPUpdateProps.regProps)) mixpanelLib.register(regProps);
  prevMPUpdateProps = { userProps, regProps };
}

export function mixpanelUninstall() {
  if (process.env.MIXPANEL_TOKEN) {
    mixpanelLib.reset();
  }
}

/*******************
 *  Share-A-Sale   *
 *******************/

let sasPayingCustomer = false;
function installShareASale(user) {
  shareASaleUpdate(user);
  if (process.env.SHAREASALE_MERCHANT_ID) {
    insertScript('https://www.dwin1.com/19038.js');
  }
}
function shareASaleUpdate(user) {
  // this may get updated before ShareASale.purchase is called when purchase is made, so delay
  setTimeout(() => {
    sasPayingCustomer = user.paying_user;
  }, 0);
}

export const ShareASale = {
  // transtype & tracking are required
  _track({ tracking, transtype, ...params }) {
    if (process.env.SHAREASALE_MERCHANT_ID) {
      const img = window.document.createElement('img');
      img.src = `https://www.shareasale.com/sale.cfm?${queryString.stringify({
        merchantID: process.env.SHAREASALE_MERCHANT_ID,
        amount: '0.00',
        ...params,
        transtype,
        tracking,
      })}`;
      img.width = 1;
      img.height = 1;
      window.document.body.appendChild(img);
    }
  },
  lead(user) {
    this._track({ tracking: user.id, transtype: 'lead' });
  },
  purchase({ amount, currency, discountCode, orderId, productId }) {
    const fixedAmt = amount.toFixed(2);
    this._track({
      tracking: orderId,
      amount: fixedAmt,
      currency: currency?.toUpperCase(),
      transtype: 'sale',
      skulist: productId,
      pricelist: fixedAmt,
      quantitylist: 1,
      couponcode: discountCode,
      newcustomer: sasPayingCustomer ? 0 : 1,
    });
  },
};

/*******************
 *   CUSTOMER.IO   *
 *******************/
function installCustomerIO(user) {
  if (process.env.CUSTOMER_IO_SITE_ID) {
    insertScript('https://assets.customer.io/assets/track.js', {
      attrs: {
        id: 'cio-tracker',
        'data-site-id': process.env.CUSTOMER_IO_SITE_ID,
        'data-auto-track-page': 'false', // manually handled
        'data-use-in-app': 'true', // enable for customer.io in-app messaging
      },
    });
    installCustomerIOProxy();
    if (!user.guest) {
      CustomerIO.identify(user);
    }
  }
}

function installCustomerIOProxy() {
  const cio = (window._cio ||= []);
  ['load', 'identify', 'sidentify', 'track', 'page'].forEach(m => {
    cio[m] = (...args) => cio.push([m, ...args]);
  });
}

export const CustomerIO = {
  track(...args) {
    if (__DEV__) console.log('CustomerIO: ', ...args);
    window._cio?.track(...args);
  },
  identify(user) {
    window._cio?.identify({
      id: user.id,
      email: user.email,
      created_at: parseInt(new Date(user.created_at).getTime() / 1000),
      first_name: user.first_name,
      last_name: user.last_name,

      school: user.school_name, // DEPRECATED
      school_id: user.school_id,
      school_name: user.school_name,
      school_short_name: user.school_short_name || user.school_name,
      school_type: user.school_type,
      user_type: user.user_type,

      timezone: CURRENT_TIMEZONE_NAME,

      _timestamp: parseInt(Date.now() / 1000),
    });
  },
  identifyAnonymous(email) {
    window._cio?.identify({ id: email });
  },
};

function getCampaignParams() {
  if (window.location.search) {
    const qsParams = queryString.parse(window.location.search);
    const params = {};
    const firstParams = {};
    let matchFound = false;
    ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'].map(t => {
      if (qsParams[t]) {
        matchFound = true;
      }
      params[`${t} [last touch]`] = qsParams[t];
      firstParams[`${t} [first touch]`] = qsParams[t] || null;
    });
    return matchFound && { params, firstParams };
  }
}

/*******************
 *      HOTJAR     *
 *******************/

export function Hotjar(...args) {
  window.hj?.(...args); // window.hj not initialized on prerender pages
}

function installHotjar(user, subscription) {
  if (process.env.HOTJAR_ID) {
    window.hj = function () {
      (window.hj.q ||= []).push(arguments);
    };
    window._hjSettings = { hjid: 638113, hjsv: 6 };
    insertScript(`https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`);
    if (!user.guest) {
      Hotjar('identify', user.id, {
        email: user.email,
        'School ID': user.school_id,
        'User Type': user.user_type,
        'Signed Up': user.created_at,
        'Subscription Status': subscription?.status,
        'Subscription Started At': subscription?.subscription_started_at,
        'Tokens Remaining': user.token_balance,
        'Paying User': user.paying_user,
      });
    }
  } else {
    window.hj = function (...args) {
      console.log('Hotjar: ', ...args);
    };
  }
}

/*******************
 *      TikTok     *
 *******************/

export function tikTokTrack(...args) {
  window.ttq?.track(...args);
}

function installTikTok(user) {
  if (process.env.TIKTOK_PIXEL_ID) {
    const t = 'ttq';
    window.TiktokAnalyticsObject = t;
    const ttq = (window[t] ||= []);
    ttq.methods = [
      'page',
      'track',
      'identify',
      'instances',
      'debug',
      'on',
      'off',
      'once',
      'ready',
      'alias',
      'group',
      'enableCookie',
      'disableCookie',
    ];
    ttq.setAndDefer = function (t, e) {
      t[e] = function () {
        t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
      };
    };
    for (let i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
    ttq.instance = function (t) {
      for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
      return e;
    };
    ttq.load = function (e, n) {
      const i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
      ttq._i ||= {};
      ttq._i[e] = [];
      ttq._i[e]._u = i;
      ttq._t ||= {};
      ttq._t[e] = +new Date();
      ttq._o ||= {};
      ttq._o[e] = n || {};

      insertScript(`${i}?sdkid=${e}&lib=${t}`);
    };
    ttq.load(process.env.TIKTOK_PIXEL_ID);
    ttq.page();
    if (!user.guest) {
      ttq.identify({
        email: user.email,
        external_id: user.id,
      });
    }
  }
}

/*******************
 *     Hubspot     *
 *******************/

function trackHubspot(...args) {
  (window._hsq ||= []).push(args);
}

function installHubspot(user) {
  if (process.env.HUBSPOT_PORTAL_ID) {
    insertScript(`//js.hs-scripts.com/${process.env.HUBSPOT_PORTAL_ID}.js`, { attrs: { id: 'hs-script-loader' } });
    if (!user.guest && user.hubspot_user) {
      trackHubspot('identify', { email: user.email, id: user.id });
    }
  }
}
// <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/{hubId}.js"></script>
