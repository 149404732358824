import { Global } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import createTheme from 'my-theme/myTheme';

import MainLayout from './MainLayout';

import CssBaseline from '@mui/material/CssBaseline';

export default function MainStyles() {
  const isBannerOpen = useSelector(state => Boolean(state.appState.site_wide_banner?.isOpen));

  const [darkMode, setDarkMode] = useState(() => {
    const ls = window.localStorage?.getItem('dark_mode');
    if (ls === 'false') return false;
    return !!ls;
    // return !!(ls || window.matchMedia?.('(prefers-color-scheme: dark)').matches);
  });
  const [pendingDarkMode, setPendingDarkMode] = useState(darkMode);

  const [darkModeTransitioning, setDarkModeTransitioning] = useState(false);
  useEffect(() => {
    setDarkMode(pendingDarkMode);
    const to = setTimeout(() => {
      setDarkModeTransitioning(false);
    }, 1000);
    return () => clearTimeout(to);
  }, [darkModeTransitioning, pendingDarkMode]);

  const updateDarkMode = useCallback(darkMode => {
    setDarkModeTransitioning(true);
    setPendingDarkMode(darkMode);
    window.localStorage?.setItem('dark_mode', !!darkMode);
  }, []);

  const theme = useMemo(() => {
    const t = createTheme(darkMode ? 'dark' : 'light');
    if (!isBannerOpen) return t;

    const APPBAR_OFFSET = t.constants.APPBAR_HEIGHT * 2;
    const APPBAR_OFFSET_UNITS = APPBAR_OFFSET / 8;

    return {
      ...t,
      constants: {
        ...t.constants,
        APPBAR_OFFSET,
        APPBAR_OFFSET_UNITS,
      },
    };
  }, [darkMode, isBannerOpen]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {darkModeTransitioning && (
        <Global
          styles={{
            '*, *:before': { transition: 'background-color 0.5s, border-color 0.5s !important' },
            'svg path, svg circle, a, button, p, span, input, h1, h2, h3, h4, h5, h6, td': {
              transition: 'background-color 0.5s, color 0.5s, fill 0.5s, stroke 0.5s, border-color 0.5s !important',
            },
          }}
        />
      )}
      <MainLayout darkMode={pendingDarkMode} onChangeDarkMode={updateDarkMode} />
    </ThemeProvider>
  );
}
